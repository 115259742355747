<template>
  <FloatingContainer>
    <div class="steps">
      <Button
        v-for="(s, i) in steps.length" :key="i"
        @click="step = i"
        :text="`Step ${s}`"
        :color="step === i ? 'blue' : ''"
      />
    </div>
  </FloatingContainer>

  <div class="step-text-container">
    <transition v-for="(s, i) in steps" :name="`slide-${direction}`">
      <div class="step-text" v-if="step === i">{{ s }}</div>
    </transition>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import Button from "@/components/Theme2/Button.vue";
import FloatingContainer from "@/components/FloatingContainer.vue";

const step = ref(0);
const { steps } = defineProps({ steps: Array });
const direction = ref('forward');

watch(step, (curr, prev) => direction.value = curr >= prev ? 'forward' : 'backward');
</script>

<style lang="scss" scoped>
.steps {
  @include hide-scrollbar;

  width: 100%;
  gap: 20px;
  display: flex;
  margin-bottom: 48px;
  white-space: nowrap;
  overflow-x: auto;
  justify-content: center;

  @include mobile {
    justify-content: flex-start;
    gap: 12px;
  }
}

.step-text-container {
  position: relative;
}

.step-text {
  text-align: center;
  font-size: 32px;
  line-height: 40px;
  max-width: 600px;
  margin-bottom: 48px;
}

.slide-forward, .slide-backward {
  &-enter-active, &-leave-active {
    transition-duration: 300ms;
    transition-timing-function: ease-in;
  }

  &-enter-from, &-leave-to {
    position: absolute;
    top: 0;
    opacity: 0;
  }

  &-enter-to, &-leave-from {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-forward-enter-from, .slide-backward-leave-to {
  transform: translateX(100%);
}

.slide-forward-leave-to, .slide-backward-enter-from {
  transform: translateX(-100%);
}
</style>